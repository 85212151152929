import { createWebHashHistory, createRouter } from "vue-router";
import HelloWorld from "../Views/HomePage.vue";
import ddcIotAuthProvider from "../utils/ddcIotAuthProvider.js";

const apiUrl = process.env.VUE_APP_API_URL || "https://iot.ddc.sze.hu/external_api";
const authProvider = ddcIotAuthProvider(apiUrl);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../Views/Login.vue"),
  },
  {
    path: "/",
    name: "Home",
    component: HelloWorld,
    meta: { requiresAuth: true },
  },
  {
    path: "/devices",
    name: "Devices",
    component: () => import("../Views/Devices/devices.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/devicesShow",
    name: "DevicesShow",
    component: () => import("../Views/Devices/devicesShow.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/bluetoothShow",
    name: "bluetoothShow",
    component: () => import("../Views/Devices/bluetoothShow.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("../Views/Users/users.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/domains",
    name: "Domains",
    component: () => import("../Views/Domains/domains.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("../Views/Settings.vue"),
    meta: { requiresAuth: true },
  },
];

const Router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

Router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    authProvider
      .checkAuth()
      .then(() => {
        next();
      })
      .catch((err) => {
        if (!err) {
          next({
            path: "/login",
          });
        } else next();
      });
  } else next();
});

export default Router;
