<template>
  <div class="card" id="homeCard">
     <div id="appbar">
      <text @click="navToHome" class="title" id="hiddenTitle"
       > &nbsp; &nbsp; &nbsp; &nbsp;DDC IoT Platform</text>

       <div class="container">
  <div class="item">
    
     <img :src="require('../assets/logo2.png')" />
  </div>
</div>
      <button
        type="button"
        id="navButtonActiveHome"
        class="btn btn-success"
        @click="navToHome"
      >
      <i class="fa-solid fa-house" id="navIcon"></i>
        <text class="navText"> Home</text>

      </button>
      <button
        type="button"
        id="navButtonDomainsHome"
        class="btn btn-success"
        @click="navToDomains"
      >
      <i class="fa-solid fa-layer-group" id="navIcon"></i>
         <text class="navText"> Domains</text>

      </button>
      <button
        type="button"
        id="navButtonDevicesHome"
        class="btn btn-success"
        @click="navToDevices"
        :disabled="this.isDisabled"
      >
      <i class="fas fa-hdd" id="navIcon"></i>
         <text class="navText"> Devices</text>

      </button>
      <button
        type="button"
        id="navButtonUsersHome"
        class="btn btn-success"
        @click="navToUsers"
      >
      <i class="fa-solid fa-users" id="navIcon"></i>
         <text class="navText"> Users</text>
  
      </button>
      <router-link to="/domains" class="Router-link"
        ><i class="fas fa-layer-group"></i>
      </router-link>
      <router-link to="/devices" class="Router-link"
        ><i class="fas fa-chalkboard"></i
      ></router-link>
      <router-link to="/users" class="Router-link"
        ><i class="fas fa-user-friends"></i> Users</router-link
      >
      <div id="profileIcon">
        <button
          type="button"
          class="btn btn-success"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          id="logoutButtonHome"
          @click="profilename"
        >
          <i class="fas fa-user-alt" id="pIcon"></i>&ensp;
          <label class="usernameText">{{ this.user }}</label>
          
          <i class="fa-solid fa-caret-down" id="pIcon"></i> &nbsp;
         
          
          
        </button>
        <div class="dropdown-menu">
          <a @click="navToSettings" class="dropdown-item" id="blockedContent"
            >Settings <i class="fas fa-cogs" id="settingsIcon"></i
          ></a>
          <a @click="logout" class="dropdown-item"
            >Logout <i class="fas fa-sign-out-alt" id="logoutIcon"></i
          ></a>
        </div>
      </div>
    </div>
    <div class="line">
      <div @Click="goToDomains()" class="panel" id="panel1">
        <br />
        <br />
        <h2 class="homeTitles">Domains</h2>
        <p id="panelIcon"><i class="fas fa-layer-group"></i></p>
        <h1 class="number"></h1>
      </div>
      <div class="panel" id="panel2">
        <br />
        <br />
        <h2 class="homeTitles">Devices</h2>
        <p id="panelIcon"><i class="fas fa-hdd"></i></p>
      </div>
      <div @Click="goToUsers()" class="panel" id="panel3">
        <br />
        <br />
        <h2 class="homeTitles">Users</h2>
        <p id="panelIcon"><i class="fas fa-users"></i></p>
      </div>
    </div>


  </div>
</template>

<script>
import ddcIotAuthProvider from "../utils/ddcIotAuthProvider.js";
const apiUrl = process.env.VUE_APP_API_URL || "https://iot.ddc.sze.hu/external_api";

const authProvider = ddcIotAuthProvider(apiUrl);

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  mounted() {
    localStorage.setItem("domain", "c57bd007-fe4b-4555-b464-21b8bb920482");
    //console.log(localStorage.getItem("domain"));
    //console.log(localStorage.getItem("theme"));
    this.themeChanger();
  },

  data() {
    return {
      user: localStorage.getItem("username"),
      isDisabled:true
    };
  },

  methods: {
    logout() {
      authProvider.logout();
      this.loggedIn = false;
      this.$router.push("/Login");
      //console.log(this.loggedIn);
      localStorage.setItem("username", "");
    },

    profilename() {
      //console.log(localStorage.getItem("username"));
    },

    titleHide() {
      if (window.width <= 5000) {
        document.getElementById("hiddenTitle").style.color = "red";
      }
    },

    navToSettings() {
      this.$router.push("/settings");
    },

    navToDevices() {
      this.$router.push("/devices");
    },

    navToHome() {
      this.$router.push("/");
    },

    navToDomains() {
      this.$router.push("/domains");
    },

    navToUsers() {
      this.$router.push("/users");
    },

    

    goToDevices() {
      this.$router.push("/Devices");
    },
    goToDomains() {
      this.$router.push("/Domains");
    },
    goToUsers() {
      this.$router.push("/Users");
    },

    themeChanger() {
      var themeValue = localStorage.getItem("theme");

      if (themeValue == "light") {
        document.getElementById("homeCard").style.backgroundColor = "white";
        document.getElementById("panel1").style.backgroundColor = "#d2d3db";
        document.getElementById("panel2").style.backgroundColor = "#d2d3db";
        document.getElementById("panel3").style.backgroundColor = "#d2d3db";
        document.getElementById("panel1").style.color = "#212529";
        document.getElementById("panel2").style.color = "#212529";
        document.getElementById("panel3").style.color = "#212529";

        document.getElementById("navButtonDomains").style.backgroundColor =
          "#d2d3db";
        document.getElementById("navButtonDomains").style.color = "black";

        document.getElementById("navButtonDevices").style.backgroundColor =
          "#d2d3db";
        document.getElementById("navButtonDevices").style.color = "black";

        document.getElementById("navButtonUsers").style.backgroundColor =
          "#d2d3db";
        document.getElementById("navButtonUsers").style.color = "black";
        document.getElementById("navButtonActive").style.backgroundColor =
          "#212529";
        document.getElementById("navButtonActive").style.color = "white";

        document.getElementById("appbar").style.backgroundColor = "#d2d3db";

        document.getElementById("logoutButton").style.backgroundColor =
          "#d2d3db";
        document.getElementById("logoutButton").style.color = "black";

        document.getElementById("logo").style.color = "black";
      }

      if (themeValue == "blue") {
        document.getElementById("homeCard").style.backgroundColor = "white";
        document.getElementById("panel1").style.backgroundColor = "dodgerblue";
        document.getElementById("panel2").style.backgroundColor = "dodgerblue";
        document.getElementById("panel3").style.backgroundColor = "dodgerblue";
        document.getElementById("panel1").style.color = "white";
        document.getElementById("panel2").style.color = "white";
        document.getElementById("panel3").style.color = "white";

        document.getElementById("navButtonDomains").style.backgroundColor =
          "dodgerblue";
        document.getElementById("navButtonDomains").style.color = "white";

        document.getElementById("navButtonDevices").style.backgroundColor =
          "dodgerblue";
        document.getElementById("navButtonDevices").style.color = "white";

        document.getElementById("navButtonUsers").style.backgroundColor =
          "dodgerblue";
        document.getElementById("navButtonUsers").style.color = "white";
        document.getElementById("navButtonActive").style.backgroundColor =
          "dodgerblue";
        document.getElementById("navButtonActive").style.color = "white";

        document.getElementById("appbar").style.backgroundColor = "dodgerblue";

        document.getElementById("logoutButton").style.backgroundColor =
          "dodgerblue";
        document.getElementById("logoutButton").style.color = "white";

        document.getElementById("logo").style.color = "white";
      }

      if (themeValue == "green") {
        document.getElementById("homeCard").style.backgroundColor = "white";
        document.getElementById("panel1").style.backgroundColor = "#42ba85";
        document.getElementById("panel2").style.backgroundColor = "#42ba85";
        document.getElementById("panel3").style.backgroundColor = "#42ba85";
        document.getElementById("panel1").style.color = "white";
        document.getElementById("panel2").style.color = "white";
        document.getElementById("panel3").style.color = "white";

        document.getElementById("navButtonDomains").style.backgroundColor =
          "#42ba85";
        document.getElementById("navButtonDomains").style.color = "white";

        document.getElementById("navButtonDevices").style.backgroundColor =
          "#42ba85";
        document.getElementById("navButtonDevices").style.color = "white";

        document.getElementById("navButtonUsers").style.backgroundColor =
          "#42ba85";
        document.getElementById("navButtonUsers").style.color = "white";
        document.getElementById("navButtonActive").style.backgroundColor =
          "#42ba85";
        document.getElementById("navButtonActive").style.color = "white";

        document.getElementById("appbar").style.backgroundColor = "#42ba85";

        document.getElementById("logoutButton").style.backgroundColor =
          "#42ba85";
        document.getElementById("logoutButton").style.color = "white";

        document.getElementById("logo").style.color = "white";
      }

      if (themeValue == "cyan") {
        document.getElementById("homeCard").style.backgroundColor = "#2a323d";
        document.getElementById("panel1").style.backgroundColor = "#159fba";
        document.getElementById("panel2").style.backgroundColor = "#159fba";
        document.getElementById("panel3").style.backgroundColor = "#159fba";
        document.getElementById("panel1").style.color = "#2a323d";
        document.getElementById("panel2").style.color = "#2a323d";
        document.getElementById("panel3").style.color = "#2a323d";

        document.getElementById("navButtonDomains").style.backgroundColor =
          "#159fba";
        document.getElementById("navButtonDomains").style.color = "#2a323d";

        document.getElementById("navButtonDevices").style.backgroundColor =
          "#159fba";
        document.getElementById("navButtonDevices").style.color = "#2a323d";

        document.getElementById("navButtonUsers").style.backgroundColor =
          "#159fba";
        document.getElementById("navButtonUsers").style.color = "#2a323d";
            document.getElementById("navButtonActive").style.backgroundColor =
          "#2a323d";
        document.getElementById("navButtonActive").style.color = "#159fba";

        document.getElementById("appbar").style.backgroundColor = "#159fba";

        document.getElementById("logoutButton").style.backgroundColor =
          "#159fba";
        document.getElementById("logoutButton").style.color = "#2a323d";

        document.getElementById("logo").style.color = "#2a323d";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.card {
  border: 0;
  width: 100vw;
  height: 100vh;
  background-color: #2a323d;
  display: flex;
  position: relative;
  overflow: hidden;
  
}
/*----------------------- Navbar ---------------------*/

#appbar {
  min-height: 50px;
  max-height: 60px;
}

#hiddenTitle {
  font-size: 20px;
  position: absolute;
  top:0px;
}

/*----------- Devices -------------*/
#navButtonDevicesHome{
   background-color: #20262e;
  border: 0;
  position: relative;
  left: 0px;
 margin-right: 5px;
 min-width: 120px;
  width:8vw;
  max-width: 200px;
   height:35px;
}

#navButtonDevicesHome:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

#navButtonDevicesHome:hover {
  background-color: #42ba85;
  color: black;
}

/*----------- Users -------------*/
#navButtonUsersHome{
   background-color: #20262e;
  border: 0;
  position: relative;
  left: 0px;
  margin-right: 5px;
 min-width: 120px;
  width:8vw;
  max-width: 200px;
   height:35px;
}

#navButtonUsersHome:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

#navButtonUsersHome:hover {
  background-color: #42ba85;
  color: black;
}

/*----------- Domains -------------*/
#navButtonDomainsHome{
   background-color: #20262e;
  border: 0;
  position: relative;
  left: 0px;
  margin-right: 5px;
 min-width: 120px;
  width:8vw;
  max-width: 200px;
   height:35px;
}

#navButtonDomainsHome:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

#navButtonDomainsHome:hover {
  background-color: #42ba85;
  color: black;
}

/*----------- Home -------------*/
#navButtonActiveHome{
  background-color: #42ba85;
  color: black;
  border: 0;
  position: relative;
   left: 0px;
  margin-right: 5px;
 min-width: 120px;
  width:8vw;
  max-width: 200px;
   height:35px;
}

#navButtonDomainsHome:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

#navButtonDomainsHome:hover {
  background-color: #42ba85;
  color: black;
}

/*----------- User, logout -------------*/

#logoutButtonHome {
  position: fixed;
  float: right;
  right: 30px;
  background-color: #20262e;
  display: flex;
  top: 0.1vh;
  width: 7%;
  border: 0;
  cursor: pointer;
   min-width: 150px;
  max-width: 200px;
  
}


#logoutButtonHome:hover {
  color: #42ba85;
  cursor: pointer;  

}

 #logoutButtonHome:focus{
     outline:none;
    box-shadow:none;
  }



#panelIcon {
  font-size: 100px;
  min-height: 30px;
  bottom: 2vh;
  position: relative;
}
.btn {
  width: 7vw;
  min-width: 75px;
  float: right;

}

.hu{
  display: none;
}

.en{
  display: block;
}

.panel {
  text-align: center;
  position: relative;
  height: 40vh;
  min-height: 250px;
  width: clamp(120px, 20vw, 250px);
  color: #42ba85;
  background: #20262e;
  border-radius: 15px;
  cursor: pointer;
  margin: 15px;
  font-size: 150%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  max-height:500px;
  float:left;
}

.panel:hover {
  color: #20262e;
  background-color: #42ba85;
  width: 25vw;
}

#activedLabel {
  position: relative;
  left: 10px;
}

.line {
  display: block;
  margin:auto;

}

.usernameText {
  margin-right: 15px;
}


/*----------------------------- Logo ---------------------------*/

* {
  box-sizing: border-box;
}
.container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 45px;
  width: 45px;
  margin-top: 2.5px;
  margin-left: 10px;
}
.item {
  align-items: center;
  display: flex;
  height: 7.5px;
  justify-content: center;
  position: relative;
  width: 7.5px;
  z-index: 100;
  img {
    object-fit: cover;
    height: 45px;
    width: 45px;
  }
  &::before,
  &::after {
    background-image: linear-gradient(-180deg, #0076a3 0%, #072b3c 97%);
    content: "";
    height: 7.5px;
    position: absolute;
    transition: all 0.2s;
    transition-delay: 0.2s;
    transform: translateY(0px) translateX(0px);
    width: 7.5px;
    z-index: -1;
  }
  &::after {
    background-image: linear-gradient(0deg, #8c9a27 0%, #d3d821 97%);
  }
  &:hover {
    animation-name: hoverPop;
    animation-duration: 0.4s;
    &::before {
      animation: slick-hover-2 3s 0.4s linear infinite;
      transform: translateY(-20px) translateX(-20px);
    }
    &::after {
      animation: slick-hover 3s 0.4s linear infinite reverse;
      transform: translateY(20px) translateX(20px);
    }
  }
}

@keyframes hoverPop {
  0%,
  100% {
    transform: scale(1);
  }
  33% {
    transform: scale(1.05);
  }
  66% {
    transform: scale(0.9);
  }
}

@keyframes slick-hover {
  0.00% {
    transform: translateY(20px) translateX(20px);
  }
  16.67% {
    transform: translateY(-13px) translateX(-9px);
  }
  33.33% {
    transform: translateY(4px) translateX(12px);
  }
  50.00% {
    transform: translateY(20px) translateX(-20px);
  }
  66.67% {
    transform: translateY(-3px) translateX(8px);
  }
  83.33% {
    transform: translateY(-15px) translateX(-11px);
  }
  100.00% {
    transform: translateY(20px) translateX(20px);
  }
}

@keyframes slick-hover-2 {
  0.00% {
    transform: translateY(-20px) translateX(-20px);
  }
  16.67% {
    transform: translateY(3px) translateX(-5px);
  }
  33.33% {
    transform: translateY(18px) translateX(-10px);
  }
  50.00% {
    transform: translateY(-20px) translateX(20px);
  }
  66.67% {
    transform: translateY(15px) translateX(-4px);
  }
  83.33% {
    transform: translateY(-1px) translateX(15px);
  }
  100.00% {
    transform: translateY(-20px) translateX(-20px);
  }
}


@media (max-width: 500px) and (min-width: 351px) {
  
  .panel {
    width: 93vw;
    left: 0vw;
    height: 25vh;
    top: 50px;
    position: relative;
  }

  .panel:hover {
    width: 93vw;
    left: 0vw;
    height: 25vh;
    top: 50px;
    position: relative;
  }
  .homeTitles {
    position: relative;
    bottom: 60px;
  }

  .card {
    display: inherit;
    overflow: scroll;
  }

  .line {
    display: inherit;
  }

  

  #panelIcon {
    position: relative;
    font-size: 100px;
    bottom: 50px;
  }

  .panel:active {
    background-color: #42ba85;
    color: #20262e;
  }



  #hiddenTitle {
    display: none;
  }

  .navText{
    display: none;
  }

 


/*----------- Navbar -------------*/
 #navButtonActiveHome {
  font-size: 100%;
    min-width: 10%;
    margin-left: 5%;
  }

  #navButtonUsersHome {
  font-size: 100%;
    min-width: 10%;
    margin-left: 0%;
  }

  #navButtonDomainsHome {
     font-size: 100%;
    min-width: 10%;
    margin-left: 0%;
  }
   #navButtonDevicesHome {
 font-size: 100%;
    min-width: 10%;
    margin-left: 0%;
  }

  #profileIcon {
    position: relative;
  }
  #logoutButtonHome {
    position: relative;
    float: left;
    right: 65%;
    border: 0;
    width: 100%;
    background-color: #20262e;
    position: relative;
    top: 0%;
  }
  
  .usernameText {
    display: none;
  }
}
@media (max-width: 1260px) and (min-width: 1011px) {
  #hiddenTitle {
    display: none;
  }
  #appbar {
    min-height: 50px;
    max-height: 60px;
  }
  .usernameText {
    display: none;
  }

   #logoutButtonHome {
    right: 0px;
  }


.panel{
  left:0;

}
}
@media (max-width: 1010px) and (min-width: 815px) {
  #hiddenTitle {
    display: none;
  }
  #appbar {
    min-height: 50px;
    max-height: 60px;
  }
  .usernameText {
    display: none;
  }

   #logoutButtonHome{
    right: 0px;
  }
#panelIcon {
  font-size: 50px;
}

.panel{
  left: 0;
   max-height:200px;
}
  /*----------- Home -------------*/
  #navButtonActiveHome {
    font-size: 14px;
    min-width: 85px;
    left:10px;
  }

  /*----------- Domains -------------*/

  #navButtonDomainsHome {
    font-size: 14px;
    min-width: 105px;
    left:10px;
  }

  /*----------- Devices -------------*/
  #navButtonDevicesHome {
    font-size: 14px;
    min-width: 95px;
    left:10px;
  }

  /*----------- Users -------------*/
 #navButtonUsersHome {
    font-size: 14px;
    min-width: 85px;
    left:10px;
  }

  /*----------- User, logout -------------*/
  #logoutButtonHome {
    font-size: 14px;
    margin-right: 20px;
    min-width: 10px;
  }
#hiddenDeleteText{
display: none;
}
}
@media (max-width: 750px) and (min-width: 501px) {
  #hiddenTitle {
    display: none;
  }
  #appbar {
    min-height: 50px;
    max-height: 60px;
  }
  .usernameText {
    display: none;
  }

   #logoutButtonHome{
    right: 0px;
  }
#panelIcon {
  font-size: 50px;
}

.panel{
  left:0vw;
   max-height:200px;
}
  /*----------- Home -------------*/
  #navButtonActiveHome {
    font-size: 14px;
    min-width: 85px;
    left:10px;
  }

  /*----------- Domains -------------*/

  #navButtonDomainsHome {
    font-size: 14px;
    min-width: 105px;
    left:10px;
  }

  /*----------- Devices -------------*/
  #navButtonDevicesHome {
    font-size: 14px;
    min-width: 95px;
    left:10px;
  }

  /*----------- Users -------------*/
 #navButtonUsersHome {
    font-size: 14px;
    min-width: 85px;
    left:10px;
  }

  /*----------- User, logout -------------*/
  #logoutButtonHome {
    font-size: 14px;
    margin-right: 20px;
    min-width: 10px;
  }
#hiddenDeleteText{
display: none;
}
}
@media (max-width: 815px) and (min-width: 750px) {
 #hiddenTitle {
    display: none;
  }
  #appbar {
    min-height: 50px;
    max-height: 60px;
  }
  .usernameText {
    display: none;
  }

   #logoutButtonHome{
    right: 0px;
  }
#panelIcon {
  font-size: 50px;
}

.panel{
  left:0;
   max-height:200px;
}
  /*----------- Home -------------*/
  #navButtonActiveHome {
    font-size: 14px;
    min-width: 85px;
    left:10px;
  }

  /*----------- Domains -------------*/

  #navButtonDomainsHome {
    font-size: 14px;
    min-width: 105px;
    left:10px;
  }

  /*----------- Devices -------------*/
  #navButtonDevicesHome {
    font-size: 14px;
    min-width: 95px;
    left:10px;
  }

  /*----------- Users -------------*/
 #navButtonUsersHome {
    font-size: 14px;
    min-width: 85px;
    left:10px;
  }

  /*----------- User, logout -------------*/
  #logoutButtonHome {
    font-size: 14px;
    margin-right: 20px;
    min-width: 10px;
  }
#hiddenDeleteText{
display: none;
}
}
@media (max-width: 351px){
  #hiddenTitle {
    display: none;
  }
  #appbar {
    min-height: 50px;
    max-height: 60px;
  }
  .usernameText {
    display: none;
  }

  
   .homeTitles {
    position: relative;
    bottom: 60px;
  }

  .card {
    display: inherit;
    overflow: scroll;
  }

  .line {
    display: inherit;
  }
 #panelIcon {
    position: relative;
    font-size: 100px;
    bottom: 50px;
  }

  .panel:active {
    background-color: #42ba85;
    color: #20262e;
     font-size: 30px;
  }


  .panel {
    width: 90vw;
    left: 0vw;
    height: 25vh;
    top: 50px;
    position: relative;
  }

  .panel:hover {
    width: 93vw;
    left: 0vw;
    height: 25vh;
    top: 50px;
    position: relative;
  }
  /*----------- Home -------------*/
  #navButtonActiveHome {
   min-width: 12%;
    margin-left: 0%;
     font-size:80%;
  }

  /*----------- Domains -------------*/

  #navButtonDomainsHome {
    min-width: 12%;
    margin-left: 0%;
     font-size:80%;
  }

  /*----------- Devices -------------*/
 #navButtonDevicesHome {
 min-width: 12%;
    margin-left: 0%;
     font-size:80%;
  }

  /*----------- Users -------------*/
 #navButtonUsersHome {
    min-width: 12%;
    margin-left: 0%;
     font-size:80%;
  }

  /*----------- User, logout -------------*/
 
 #profileIcon {
    position: relative;
  }
  #logoutButtonHome {
   right: 0%;
    width: 10%;
     right: -25vw;
  }
 

#hiddenDeleteText{
display: none;
}
.usernameText {
    display: none;
  }
  
  #hiddenTitle {
    display: none;
  }

  .navText{
    display: none;
  }





}


</style>
